import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  interviewJobTitle:  null,
  interviewEmployer: null,
  codingInterview: null,
  numCodingQuestions: null
};

const interviewSlice = createSlice({
  name: 'interview',
  initialState,
  reducers: {
    setInterviewJobTitle(state, action) {
      state.interviewJobTitle = action.payload;
    },
    setInterviewEmployer(state, action) {
      state.interviewEmployer = action.payload;
    },
    setCodingInterview(state, action) {
      state.codingInterview = action.payload;
    },
    setNumCodingQuestions(state, action) {
      state.numCodingQuestions = action.payload
    },
    resetAuth(state) {
      state.interviewJobTitle = null;
      state.interviewEmployer = null;;
    },
  },
});

export const { setInterviewJobTitle, setInterviewEmployer, setCodingInterview, setNumCodingQuestions} = interviewSlice.actions;
export default interviewSlice.reducer;
