import React, { useCallback, useEffect, useState } from "react"
import PossoHeader from "../PossoHeader"
import '../home-page-fonts/HelveticaNowDisplay/stylesheet.css'
import '../home-page-fonts/uicons-thin-straight/css/uicons-thin-straight.css'
import './custom.css'
import { useSearchParams } from "react-router-dom"
import { Button, Form } from "react-bootstrap"
import { ResetPassword, VerifyEmail } from "../FirebaseAuth"

export const AuthAction = (props) => {
    const [searchParams] = useSearchParams()
    const [firebaseParams, setFirebaseParams] = useState({ mode: null, oobCode: null })
    const [error, setError] = useState('')
    const [pwResetSuccess, setPwResetSuccess] = useState(false)
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [emailVerified, setEmailVerified] = useState(false)

    const emailVerify = useCallback(async (oobCode) => {
        try {
            await VerifyEmail(oobCode)
            setEmailVerified(true)
        }
        catch (error) {
            setError("Error: " + error.message)
        }
    }, [])

    useEffect(() => {
        let timer;
        if (emailVerified) {
            timer = setTimeout(() => {
                window.location.href = `${process.env.REACT_APP_REDIRECT}organization`
            }, 3000)
        }

        return () => clearTimeout(timer)
    },[emailVerified])

    useEffect(() => {
        if (searchParams.get('mode') === 'verifyEmail') {
            emailVerify(searchParams.get('oobCode'))
        }

        if (searchParams.has('mode') && searchParams.has('oobCode')) {
            setFirebaseParams(() => ({
                mode: searchParams.get('mode'),
                oobCode: searchParams.get('oobCode')
            }))
        }
        else {
            setError('Error: Invalid link or expired code.')
        }


    }, [searchParams, emailVerify])

    const handlePasswordChange = (event) => {
        setNewPassword(event.currentTarget.value)
    }

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.currentTarget.value)
    }

    const handleResetPassword = async (event) => {
        event.preventDefault()
        setError('')
        if (newPassword.length < 7) {
            setError('Error: Password should be atleast 7 characters.')
            return
        }
        if (newPassword !== confirmPassword) {
            setError('Error: Passwords do not match.')
            return
        }
        try {
            await ResetPassword(firebaseParams.oobCode, newPassword)
            setPwResetSuccess(true)
        }
        catch (error) {
            setError("Error: " + error.message)
        }

    }

    return <>
        <PossoHeader />
        <div className="container-fluid bg-lightgray py-4 admin-content shadow-1" style={{ height: "100vh" }}>
            {
                firebaseParams.mode === 'resetPassword' &&
                <>
                    <div className="ms-auto me-auto" style={{ maxWidth: 400 + "px" }}>

                        <h1 className="fs-3 fw-bold mb-4 ms-auto me-auto text-center mt-5">Reset Password</h1>
                        <Form onSubmit={handleResetPassword} className="contact-us">
                            <Form.Control type="password" className="mb-3" value={newPassword} onChange={handlePasswordChange} placeholder="New Password"></Form.Control>
                            <Form.Control type="password" className="mb-3" value={confirmPassword} onChange={handleConfirmPasswordChange} placeholder="Confirm New Password"></Form.Control>
                            <div className="text-center mt-4 mb-4">
                                <Button variant="primary" type="submit">Reset Password</Button>
                            </div>
                        </Form>
                    </div>
                </>
            }
            {
                firebaseParams.mode === 'verifyEmail' &&
                <>
                </>
            }
            {
                error &&
                <div class="text-danger h6 text-center">{error}</div>
            }
            {
                pwResetSuccess &&
                <div class="text-success h6 text-center">
                    <span>Password is reset successfully. Please <a href={`${process.env.REACT_APP_REDIRECT}?orgsignin`}>sign in</a></span>
                </div>
            }
            {
                emailVerified &&
                <div class="text-success h5 text-center">
                    <span>
                        Your email has been successfully verified. You will be automatically redirected to the dashboard shortly. <br />
                        If the redirection does not occur, please <a href={`${process.env.REACT_APP_REDIRECT}organization`}>click here</a> to access the dashboard directly.
                    </span>
                </div>
            }
        </div>
    </>

}

export default AuthAction
