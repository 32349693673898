import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import AuthUX from './AuthUX';
import { Link } from 'react-router-dom';
import useIsMobile from './hooks/useIsMobile';


function PossoHeader() {
    const isMobile = useIsMobile()

    return (
        <>
            <Navbar bg="light" expand="lg" variant="light" className="border-bottom " fixed="top">
                <div className="container-fluid">
                    <Navbar.Brand id="logotop" href="/" className="mt-3 mb-3">
                        <img src="/assets/images/logo/logo.svg" width="200" alt="" />
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="basic-navbar-nav">
                        <span class="navbar-toggler-icon"></span>

                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto align-items-end">
                            {isMobile ?
                                <>
                                    <AuthUX appHeader={false} />
                                    <Link to="/careers" className="nav-link me-lg-4 p-2 mobile-divider">
                                        Careers
                                    </Link>

                                </>
                                :
                                <>
                                    <Link to="/careers" className="nav-link me-lg-4">
                                        Careers
                                    </Link>
                                    <AuthUX appHeader={false}/>
                                </>
                            }
                        </Nav>
                    </Navbar.Collapse>

            </div>
        </Navbar >
            <div className="header-padding" />

        </>
    )
}

export default PossoHeader;
