import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  userType: null,
  name: '',
  employerId: -1,
  email: ''
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsLoggedIn(state, action) {
      state.isLoggedIn = action.payload;
    },
    setUserType(state, action) {
      state.userType = action.payload;
    },
    setName(state, action) {
      state.name = action.payload;
    },
    setEmployerId(state, action) {
      state.employerId = action.payload;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    resetAuth(state) {
      state.isLoggedIn = false;
      state.userType = null;
      state.name = '';
      state.employerId = -1;
      state.email = '';
    },
  },
});

export const { setIsLoggedIn, setUserType, setName, setEmployerId, setEmail, resetAuth } = authSlice.actions;
export default authSlice.reducer;
