import { useEffect, useState, useRef } from "react";
import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";
import { OAuthProvider } from "firebase/auth"
import "firebaseui/dist/firebaseui.css"
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap"
import './firebase-overrides.css'

const firebaseConfig = {
    apiKey: "AIzaSyDv_UiZX3US-VWKo2TTwzEN_gDBC6NBto4",
    authDomain: "candidatesignin.possobuild.ai",
    projectId: "posso-build",
    storageBucket: "posso-build.appspot.com",
    messagingSenderId: "216949993766",
    appId: "1:216949993766:web:fbe2c739a954f4217e51e7"
};

const orgfirebaseConfig = {
    apiKey: "AIzaSyDxAU0hhvjael-BcLuzUoK_bZc7Rg6ydCE",
    authDomain: "orgauth.possobuild.ai",
    projectId: "possosmarthireorg",
    storageBucket: "possosmarthireorg.appspot.com",
    messagingSenderId: "727609398872",
    appId: "1:727609398872:web:d89170d24e47b4b861dc2d",
    measurementId: "G-6M13NWSD12"
};

const adminfirebaseConfig = {
    apiKey: "AIzaSyA31FAJ9z-ukePiexDOR4xSlPQGROLdzh4",
    authDomain: "possobuild-admin.firebaseapp.com",
    projectId: "possobuild-admin",
    storageBucket: "possobuild-admin.appspot.com",
    messagingSenderId: "13793159256",
    appId: "1:13793159256:web:75aecfbfc543c38e83422d"
};


firebase.initializeApp(firebaseConfig);
const orgapp = firebase.initializeApp(orgfirebaseConfig, "org");
const adminapp = firebase.initializeApp(adminfirebaseConfig, "admin")

const delay = ms => new Promise(res => setTimeout(res, ms));

export const RefreshOrgUserToken = async (check_email_verification = false) => {

    const auth1 = firebase.auth(orgapp)
    let i = 0
    while (auth1.currentUser == null && i < 20) {
        await delay(250)
        i++
    }
    if (auth1.currentUser != null) {

        const idToken = await auth1.currentUser.getIdToken(true)
        if (check_email_verification && auth1.currentUser.emailVerified === false) {
            return false
        }

        const idTokenJson = "{ \"idToken\":\"" + idToken + "\", \"userType\":\"orgUser\"}";
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: idTokenJson
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/session_login`, requestOptions)
        const result = await response.json()
        if (check_email_verification && result.status === "force_verify_email") {
            return false
        }
        return true
    }
}

export const ResendVerificationEmail = async () => {

    const auth1 = firebase.auth(orgapp)
    let i = 0
    while (auth1.currentUser == null && i < 20) {
        await delay(250)
        i++
    }
    if (auth1.currentUser != null) {
        try {
            auth1.currentUser.sendEmailVerification({
                'url': `${process.env.REACT_APP_REDIRECT}organization`
            });
            return true
        }
        catch {
            return false
        }
    }
    return false
}

export const ResetPassword = async (oobCode, newPassword) => {
    try {
        await firebase.auth(orgapp).confirmPasswordReset(oobCode, newPassword)
    }
    catch (error) {
        switch (error.code) {
            case 'auth/expired-action-code':
                throw new Error('The reset code has expired. Please request a new one.')
            case 'auth/invalid-action-code':
                throw new Error('The reset code is invalid. Please check the link or request a new one.')
            case 'auth/user-disabled':
                throw new Error('The user corresponding to the given reset code has been disabled.')
            case 'auth/user-not-found':
                throw new Error('There is no user corresponding to the reset code.')
            default:
                throw new Error('An unexpected error occurred. Please try again.')
        }
    }
}

export const VerifyEmail = async (oobCode) => {
    try {
        await firebase.auth(orgapp).applyActionCode(oobCode)
    }
    catch (error) {
        switch (error.code) {
            case 'auth/expired-action-code':
                throw new Error('The verification link has expired. Please request a new verification email.')
            case 'auth/invalid-action-code':
                throw new Error('The verification link is invalid. This can happen if the link is malformed or has already been used.')
            case 'auth/user-disabled':
                throw new Error('The user account associated with this email has been disabled.')
            case 'auth/user-not-found':
                throw new Error('No user found for this verification request. The account may have been deleted.')
            // Add more cases as needed for different Firebase error codes
            default:
                throw new Error('An unexpected error occurred. Please try again.')
        }
    }
}

export const FirebaseAuthTrialOrgSignUp1 = (signupForm, navigate) => {
    const app = orgapp
    firebase.auth(app).createUserWithEmailAndPassword(signupForm.email, signupForm.password)
        .then(async (authResult) => {
            await authResult.user.updateProfile({ displayName: signupForm.name })
            await postAuth(authResult, app, "orgUser", navigate, signupForm)
            authResult.user.sendEmailVerification({
                'url': `${process.env.REACT_APP_REDIRECT}organization`
            });
        })
        .catch(() => {
            return false
        })
};

export const FirebaseAuthOrg = () => {

    const [state, setState] = useState({ getpass: false });
    const [email, setEmail] = useState()
    const [error, setError] = useState('')

    const navigate = useNavigate();

    const fbAppRef = useRef(null)

    const handleLogin = (event) => {
        event.preventDefault();
        setError('')
        const app = fbAppRef.current;
        firebase.auth(app).signInWithEmailAndPassword(event.target.elements['email'].value, event.target.elements['password'].value)
            .then((authResult) => {
                postAuth(authResult, app, 'orgUser', navigate)
            })
            .catch((error) => {
                if (error.code === "auth/wrong-password") {
                    setError("wrong_password")
                }
            });
    }

    const handleEmailChange = (event) => {
        setEmail(event.currentTarget.value)
    }

    const handleForgotPassword = (event) => {
        event.preventDefault();
        const app = fbAppRef.current;
        firebase.auth(app).sendPasswordResetEmail(email)
            .then(() => {
                setError("sent_pwreset_email")
            })
    }

    const handleEmailSubmit = (event) => {
        event.preventDefault();
        setError('')
        const requestOptions = {
            method: 'GET',
        };
        fetch(`${process.env.REACT_APP_API_URL}/get_idp?u=` + event.target.elements['email'].value, requestOptions)
            .then((res) => {
                res.json()
                    .then((idpresult) => {
                        if (idpresult["status"] === "failed") {
                            if (idpresult["reason"] === "user_notfound") {
                                setError("user_notfound")
                            }
                            else {
                                setError("unknown_error")
                            }
                            return
                        }
                        const idp = idpresult["idProvider"];
                        const app = orgapp;
                        firebase.auth(app).setPersistence(firebase.auth.Auth.Persistence.LOCAL);
                        fbAppRef.current = app
                        let provider = ""
                        if (idp === "EmailPassword") {
                            setState({ ...state, getpass: true })
                        } else {
                            provider = new OAuthProvider(idp)
                            provider.setCustomParameters({
                                login_hint: event.target.elements['email'].value
                            });
                            firebase.auth(app).signInWithPopup(provider)
                                .then((authResult) => {
                                    postAuth(authResult, app, 'orgUser', navigate)

                                })
                                .catch((error) => {
                                })
                        }
                    })
            })
    }

    return (
        <>
            <div className="status-message-container mb-2 text-center">
                {error === "wrong_password" && <Form.FloatingLabel className="text-danger">Wrong password.</Form.FloatingLabel>}
                {error === "sent_pwreset_email" && <Form.FloatingLabel className="text-success">Password reset email is sent.</Form.FloatingLabel>}
                {error === "user_notfound" && <Form.FloatingLabel className="text-danger">User is not registered. <a href={`${process.env.REACT_APP_REDIRECT}?signup`}>Signup</a> for trial.</Form.FloatingLabel>}
            </div>
            <Form onSubmit={state.getpass === true ? handleLogin : handleEmailSubmit}>
                <Form.Control type="email" className="mb-4" placeholder="Enter email" name="email" onChange={handleEmailChange} disabled={state.getpass}/>
                {
                    state.getpass === true
                        ?
                        <>
                            <Form.Control type="password" placeholder="Enter password" name="password" />
                            <div className="mt-1">
                                <Button variant="link" className="ps-0 text-decoration-none" onClick={handleForgotPassword}>Forgot password?</Button>
                            </div>
                        </>
                        : null
                }
                <div className="col text-end">
                    <Button className="mt-0 ps-4 pe-4 pt-1 pb-1" type="submit">
                        Sign in
                    </Button>
                </div>
            </Form>
        </>
    )
}

export const FirebaseAdminAuth = (navigateFunc) => {
    firebase.auth(adminapp).setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    firebase.auth(adminapp).signInWithPopup(new OAuthProvider('oidc.possobuildaad'))
        .then((authResult) => {
            postAuth(authResult, adminapp, "adminUser", navigateFunc)

        })
        .catch((error) => {
        })
}

export const FirebaseAuth = (props) => {
    let navigate = useNavigate();
    useEffect(() => {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
        ui.start("#firebase-auth-container", {
            signInOptions: [
                {
                    provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                    customParameters: {
                        prompt: 'select_account'
                    }
                },
                {
                    provider: "microsoft.com",
                    customParameters: {
                        prompt: 'select_account'
                    }
                },
            ],
            callbacks: {

                signInSuccessWithAuthResult: function (authResult, redirectUrl) {
                    authResult.user.getIdToken().then(idToken => {
                        const idTokenJson = "{ \"idToken\":\"" + idToken + "\", \"userType\":\"aspUser\" }";
                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            credentials: 'include',
                            body: idTokenJson
                        };

                        fetch(`${process.env.REACT_APP_API_URL}/session_login`, requestOptions)
                            .then(response => response.json())
                            .then(
                                (result) => {
                                    if (result['status'] === 'refresh_token') {
                                        firebase.auth().currentUser.getIdToken(true).then(idToken => {
                                            const idTokenJson = "{ \"idToken\":\"" + idToken + "\", \"userType\":\"aspUser\"}";
                                            const requestOptions = {
                                                method: 'POST',
                                                headers: { 'Content-Type': 'application/json' },
                                                credentials: 'include',
                                                body: idTokenJson
                                            };
                                            fetch(`${process.env.REACT_APP_API_URL}/session_login`, requestOptions)
                                                .then(response => response.json())
                                                .then((apiresult2) => {
                                                    if (typeof props.redirect !== 'undefined' && props.redirect !== undefined && props.redirect !== null) {
                                                        if (typeof props.state !== 'undefined' && props.state !== undefined && props.state !== null) {
                                                            navigate(props.redirect, { state: props.state })
                                                        }
                                                        else {
                                                            navigate(props.redirect)
                                                        }
                                                    }
                                                    else if (authResult.additionalUserInfo.isNewUser) {
                                                        navigate('/aspirant');
                                                    }
                                                    else {
                                                        navigate('/aspirant');
                                                    }
                                                })
                                        })
                                    }
                                    else if (result['status'] === 'failed') {
                                        navigate('/aspirant_error')
                                    } else {
                                        if (typeof props.redirect !== 'undefined' && props.redirect !== undefined && props.redirect !== null) {
                                            if (typeof props.state !== 'undefined' && props.state !== undefined && props.state !== null)
                                                navigate(props.redirect, { state: props.state })
                                            else
                                                navigate(props.redirect)
                                        }
                                        else if (authResult.additionalUserInfo.isNewUser) {
                                            navigate('/aspirant');
                                        }
                                        else {
                                            navigate('/aspirant');
                                        }
                                    }
                                }
                            )

                    })

                    //await new Promise(r => setTimeout(r, 2000));

                    return false;
                },

                uiShown: function () {
                    document.getElementById('loader').style.display = 'none';
                }
            },
            signInFlow: 'popup',
            // signInSuccessUrl: 'https://sh.possobuild.ai/aspirant',
            // tosUrl: 'https://sh.possobuild.ai/',
            // privacyPolicyUrl: 'https://sh.possobuild.ai/'
        })
    })
};

const postAuth = async (authResult, fbapp, userType, navigateFunc, signupForm = null) => {

    const NavigateToError = (userType) => {
        switch (userType) {
            case 'aspUser':
                navigateFunc('/aspirant_error');
                break;
            case 'orgUser':
                navigateFunc('/organization_error');
                break;
            case 'adminUser':
                navigateFunc('/admin_error')
                break;
            default:
                navigateFunc(('/organization_error'))

        }
    }

    const NavigateForNewUser = (userType) => {
        switch (userType) {
            case 'aspUser':
                navigateFunc('/aspirant_profile');
                break;
            case 'orgUser':
                navigateFunc('/organization/settings');
                break;
            case 'adminUser':
                navigateFunc('/admin/dashboard')
                break;
            default:
                navigateFunc(('/organization/settings'))

        }
    }

    const NavigateForExistingUser = (userType) => {
        switch (userType) {
            case 'aspUser':
                navigateFunc('/aspirant');
                break;
            case 'orgUser':
                navigateFunc('/organization');
                break;
            case 'adminUser':
                navigateFunc('/admin/dashboard')
                break;
            default:
                navigateFunc(('/organization'))

        }
    }

    authResult.user.getIdToken()
        .then(idToken => {
            const idTokenJson = {
                "idToken": idToken,
                "userType": userType,
                "signupForm": signupForm
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(idTokenJson)
            };
            fetch(`${process.env.REACT_APP_API_URL}/session_login`, requestOptions)
                .then(response => response.json())
                .then((apiresult) => {
                    if (apiresult['status'] === 'refresh_token') {
                        firebase.auth(fbapp).currentUser.getIdToken(true).then(idToken => {
                            const idTokenJson = {
                                "idToken": idToken,
                                "userType": userType
                            }
                            const requestOptions = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                credentials: 'include',
                                body: JSON.stringify(idTokenJson)
                            };
                            fetch(`${process.env.REACT_APP_API_URL}/session_login`, requestOptions)
                                .then(response => response.json())
                                .then((apiresult2) => {
                                    if (apiresult2['status'] === 'failed') {
                                        NavigateToError(userType)
                                    } else {
                                        if (authResult.additionalUserInfo.isNewUser || apiresult2['status'] === 'force_profile') {
                                            NavigateForNewUser(userType)
                                        }
                                        else {
                                            NavigateForExistingUser(userType)
                                        }
                                    }
                                })
                        })
                    }
                    else if (apiresult['status'] === 'failed') {
                        NavigateToError(userType)
                    } else {
                        if (authResult.additionalUserInfo.isNewUser || apiresult['status'] === 'force_profile') {
                            NavigateForNewUser(userType)
                        }
                        else {
                            NavigateForExistingUser(userType)
                        }
                    }
                }
                )
        })
}