import React from 'react';
import './LoadingOverlay.css'; // Create this file for custom styling

const LoadingOverlay = (props) => {
  // const textToDisplay = loadingText ? loadingText.toString() : '';
  return <>
    <div className={props.loading ? 'container-fluid overlay visible' : 'overlay'}>
      <div className="row justify-content-center align-items-center" style={{ height: "100%" }}>
        <div className="d-flex flex-column align-items-center">
          <div className="spinner-border text-primary" role="status">
            <span className='visually-hidden'>{props.loadingText}</span>
          </div>
          <div className="text-center mt-2"><strong>{props.loadingText}</strong></div>
        </div>
      </div>
    </div>

  </>
};

export default LoadingOverlay;