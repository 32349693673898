import { useState, useEffect } from "react";

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(true)


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 992)
        }

        handleResize();

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    },[])

    return isMobile;
}

export default useIsMobile;