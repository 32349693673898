import PossoDropdownToggle from "./PossoDropdownToggle";
import { Dropdown } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import useIsMobile from "./hooks/useIsMobile";

function PossoDropdown(props) {
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    function logOut(redirect_url) {
        const requestOptions = {
            method: 'POST',
            credentials: 'include'
        };
        fetch(`${process.env.REACT_APP_API_URL}/session_logout`, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    window.location.href = redirect_url
                    return false;
                }
            )
    }

    function goAspirantHome() {
        navigate('/aspirant')
    }

    // function goOrganizationHome() {
    //     navigate('/organization')
    // }

    function goProfile() {
        navigate('/aspirant_profile')
    }

    function goScheduleInterview() {
        navigate('/aspirant_select_company')
    }

    function goBrowseInterviews() {
        navigate('/aspirant_results')
    }

    function goOrgHome() {
        navigate('/organization')
    }

    function goManagejobs() {
        navigate('/organization/jobs')
    }

    function goManageCandidates() {
        navigate('/organization/candidates')
    }

    function goOrgSettings() {
        navigate('/organization/settings')
    }

    function goAdminDashboard() {
        navigate('/admin/dashboard')
    }

    return <>
        {isMobile && props.appHeader === false ?
            props.userType === 'orgUser'
                ?
                <>
                    <Link id="menuorgdashlnk" className="nav-link me-lg-e p-1" onClick={goOrgHome}>Dashboard</Link>
                    <Link id="menumgjoblnk" className="nav-link me-lg-e p-1" onClick={goManagejobs}>Manage Jobs</Link>
                    <Link id="menumgcandlnk" className="nav-link me-lg-e p-1" onClick={goManageCandidates}>Manage Candidates</Link>
                    <Link id="menuorgsetting" className="nav-link me-lg-e p-1" onClick={goOrgSettings}>Settings</Link>
                    <hr className="dropdown-divider" />
                    <Link id="menuorgsignoutlnk" className="nav-link me-lg-e p-1" onClick={() => logOut(`${process.env.REACT_APP_REDIRECT}`)}>Sign out</Link>
                </>
                : props.userType === 'adminUser' ?
                    <>
                        <Link id="menuaspdashlnk" className="nav-link me-lg-e p-1" onClick={goAdminDashboard}>Dashboard</Link>
                    </>
                    :
                    <>
                        <Link id="menuaspdashlnk" className="nav-link me-lg-e p-1" onClick={goAspirantHome}>Dashboard</Link>
                        <Link id="menuaspschlnk" className="nav-link me-lg-e p-1" onClick={goScheduleInterview}>Schedule interview</Link>
                        <Link id="menuaspbrowselnk" className="nav-link me-lg-e p-1" onClick={goBrowseInterviews}>My interviews</Link>
                        <Link id="menuaspprofilelnk" className="nav-link me-lg-e p-1" onClick={goProfile}>Account settings</Link>
                        <hr className="dropdown-divider" />
                        <Link id="menuaspsignoutlnk" className="nav-link me-lg-e p-1" onClick={() => logOut('https://smartprep.possobuild.ai')}>Sign out</Link>
                    </>
            :
            <Dropdown align="end" className="ms-auto">
                <PossoDropdownToggle userLabel={props.userLabel} picture={props.picture} />
                {
                    props.userType === 'orgUser'
                        ?

                        <Dropdown.Menu variant='light'>
                            <Dropdown.Item id="menuorgdashlnk" onClick={goOrgHome}>Dashboard</Dropdown.Item>
                            <Dropdown.Item id="menumgjoblnk" onClick={goManagejobs}>Manage Jobs</Dropdown.Item>
                            <Dropdown.Item id="menumgcandlnk" onClick={goManageCandidates}>Manage Candidates</Dropdown.Item>
                            <Dropdown.Item id="menuorgsetting" onClick={goOrgSettings}>Settings</Dropdown.Item>

                            <hr className="dropdown-divider" />
                            <Dropdown.Item id="menuorgsignoutlnk" onClick={() => logOut(`${process.env.REACT_APP_REDIRECT}`)}>Sign out</Dropdown.Item>
                        </Dropdown.Menu>

                        : props.userType === 'adminUser' ?
                            <Dropdown.Menu variant='light'>
                                <Dropdown.Item id="menuaspdashlnk" onClick={goAdminDashboard}>Dashboard</Dropdown.Item>
                                <hr className="dropdown-divider" />
                                <Dropdown.Item id="menuaspsignoutlnk" onClick={() => logOut('/admin')}>Sign out</Dropdown.Item>
                            </Dropdown.Menu>
                            :
                            <Dropdown.Menu variant='light'>
                                <Dropdown.Item id="menuaspdashlnk" onClick={goAspirantHome}>Dashboard</Dropdown.Item>
                                <Dropdown.Item id="menuaspschlnk" onClick={goScheduleInterview}>Schedule interview</Dropdown.Item>
                                <Dropdown.Item id="menuaspbrowselnk" onClick={goBrowseInterviews}>My interviews</Dropdown.Item>
                                <Dropdown.Item id="menuaspprofilelnk" onClick={goProfile}>Account settings</Dropdown.Item>
                                <hr className="dropdown-divider" />
                                <Dropdown.Item id="menuaspsignoutlnk" onClick={() => logOut('https://smartprep.possobuild.ai')}>Sign out</Dropdown.Item>
                            </Dropdown.Menu>
                }
            </Dropdown>
        }
    </>
}

export default PossoDropdown;