import { Dropdown } from "react-bootstrap";

var getInitials = function (string) {
    var names = string.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();
    
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

function PossoInitials(props) {
    var posso_inititals = getInitials(String(props.userLabel));
    return posso_inititals;
}

function PossoDropdownToggle(props) {
    if(window.matchMedia("(max-width: 767px)").matches){
        if(props.picture !== undefined && !props.picture.startsWith('https://graph.microsoft.com')) {
            const content = <img alt='profile-pic' width='28px' src={props.picture}/>
            return (
                <>
                    <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
                        {content}
                    </Dropdown.Toggle>
                    </>
            )
       }
       else {
            return (
                <>
                    <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
                        <PossoInitials userLabel={props.userLabel}/>
                    </Dropdown.Toggle>
                    </>
            );
       }
    }
    else{

        var content = "";
        if(props.picture !== undefined && !props.picture.startsWith('https://graph.microsoft.com')) {
            content = <img alt='profile-pic' width='35px' src={props.picture} className='pe-2'/>
        }
        return ( 
            <>
                <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
                    {content}<span className="align-middle">{props.userLabel}</span><span> </span>
                </Dropdown.Toggle>
                </>
        )
    }
}

export default PossoDropdownToggle;