import { Dropdown } from 'react-bootstrap';
import useIsMobile from './hooks/useIsMobile';
import { Link } from 'react-router-dom';

function AuthDropdown(props) {
        let isMobile = useIsMobile();
        return (
                <>
                        {isMobile ?
                                <>
                                        <Link onClick={props.handleShowOrg} className="nav-link me-lg-e p-2">Organization Sign in</Link>
                                        <Link onClick={props.handleShowSignup} className="nav-link me-lg-e p-2">Organization Trial Signup</Link>
                                        <Link onClick={props.handleShowAsp} className="nav-link me-lg-e p-2">Candidate Sign in</Link>
                                </>
                                :
                                <Dropdown align="end" className="ms-auto">
                                        <Dropdown.Toggle variant="outline-dark">Sign In</Dropdown.Toggle>
                                        <Dropdown.Menu variant='light'>
                                                <Dropdown.Item onClick={props.handleShowOrg}>Organization Sign in</Dropdown.Item>
                                                <hr className="dropdown-divider" />
                                                <Dropdown.Item onClick={props.handleShowSignup}>Organization Trial Signup</Dropdown.Item>
                                                <hr className="dropdown-divider" />
                                                <Dropdown.Item onClick={props.handleShowAsp}>Candidate Sign in</Dropdown.Item>
                                        </Dropdown.Menu>
                                </Dropdown>
                        }
                </>
        )

}

export default AuthDropdown;