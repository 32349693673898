import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import React, { createContext, useState } from "react";
import LoadingOverlay from "./components/LoadingOverlay";
import { useEffect } from "react";
import { RefreshOrgUserToken } from "./FirebaseAuth";

// Need to change to use something local UMA
export const ProtectedRoutesAspirant = () => {

    let [authState, setAuthState] = useState({ dataPresent: false, auth_status: false, profile_redirect: false })

    useEffect(() => {

        const requestOptions = {
            method: 'GET',
            credentials: 'include'
        };

        fetch(`${process.env.REACT_APP_API_URL}/check_login`, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result["result"] !== undefined && result["result"] === "success" && result["userType"] !== undefined && result["userType"] === "aspUser") {
                        setAuthState((prevState) => ({
                            ...prevState,
                            dataPresent: true,
                            auth_status: true
                        }))
                    }
                    else {
                        setAuthState((prevState) => ({
                            ...prevState,
                            dataPresent: true,
                            auth_status: false
                        }))
                    }
                }
            )
    }, [])

    if (authState.dataPresent === false) {
        return (
            <LoadingOverlay loading={true} />
        )
    } else {
        return (
            authState.auth_status ? <Outlet /> : <Navigate to="/forceaspirantlogin" />
        )
    }
}

export const ResetOrgAuthContext = createContext({
    resetAuthState: () => { }
});

export const ProtectedRoutesOrganization = () => {

    const [authState, setAuthState] = useState({
        dataPresent: false,
        auth_status: false,
        profile_redirect: false,
        verify_email: false
    })

    const [resetAuthStateCounter, setResetAuthStateCounter] = useState(0)

    let location = useLocation()
    let navigate = useNavigate()

    const resetAuthState = () => {
        setAuthState(() => ({
            dataPresent: false,
            auth_status: false,
            profile_redirect: false,
            verify_email: false
        }))
        setResetAuthStateCounter((prevState) => (prevState + 1))
    }

    useEffect(() => {

        const requestOptions = {
            method: 'GET',
            credentials: 'include'
        };

        fetch(`${process.env.REACT_APP_API_URL}/check_login`, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.result === "success" && result.userType === "orgUser") {

                        //email_verified is not present in the response it is not required verify email
                        if (result.email_verified === false) {
                            // Refresh token to check if email was verified since last session creation
                            let verify_email = false

                            RefreshOrgUserToken(true).then((email_verified) => {

                                if (email_verified === false) {
                                    verify_email = true
                                }
                                setAuthState((prevState) => ({
                                    ...prevState,
                                    dataPresent: true,
                                    auth_status: true,
                                    verify_email: verify_email,
                                    profile_redirect: false
                                }))
                            })
                        }
                        else {
                            let pr_redir = true;
                            if (result.employer_id !== undefined && result.employer_id !== -1) {
                                pr_redir = false;
                            }
                            setAuthState((prevState) => ({
                                ...prevState,
                                dataPresent: true,
                                auth_status: true,
                                profile_redirect: pr_redir,
                                verify_email: false
                            }))
                        }
                    }
                    else {
                        setAuthState((prevState) => ({
                            ...prevState,
                            dataPresent: true,
                            auth_status: false
                        }))
                    }
                }
            )
    }, [location.pathname, resetAuthStateCounter])

    if (authState.dataPresent === false) {
        return (
            <LoadingOverlay loading={true} />
        )
    }
    else {
        if (!authState.auth_status) {
            navigate('/forceorglogin')
        }
        if (authState.verify_email && location.pathname !== '/organization/verify_email') {
            navigate('/organization/verify_email')
        }
        if (authState.profile_redirect && location.pathname !== '/organization/settings') {
            navigate('/organization/settings')
        }
        if (authState.verify_email === false && location.pathname === '/organization/verify_email') {
            navigate('/organization')
        }
        return (
            <ResetOrgAuthContext.Provider value={{ resetAuthState }}>
                <Outlet />
            </ResetOrgAuthContext.Provider>
        )

    }
}

export const ProtectedRoutesAdmin = () => {

    let [authState, setAuthState] = useState({ dataPresent: false, auth_status: false })

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            credentials: 'include'
        };

        fetch(`${process.env.REACT_APP_API_URL}/check_login`, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result["result"] !== undefined && result["result"] === "success" && result["userType"] !== undefined && result["userType"] === "adminUser") {
                        setAuthState((prevState) => ({
                            ...prevState,
                            dataPresent: true,
                            auth_status: true
                        }))
                    }
                    else {
                        setAuthState((prevState) => ({
                            ...prevState,
                            dataPresent: true,
                            auth_status: false
                        }))
                    }
                }
            )

    }, [])

    if (authState.dataPresent === false) {
        return (
            <LoadingOverlay loading={true} />
        )
    } else {
        return (
            authState.auth_status ? <Outlet /> : <Navigate to="/forceorglogin" />
        )
    }
}