import { React } from 'react';
import { Modal } from "react-bootstrap"
import { TrialSignUpOrg } from './TrialSignUpOrg';

const TrialSignUpOrgModal = ({ show,  onHide, email}) => {

  return (
    <Modal show={show} onHide={onHide} className="no-border">
      <Modal.Header closeButton className='gr-bg text-light'>
        <button className="btn-close invisible" disabled={true}></button>
        <Modal.Title>Sign up for Trial</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TrialSignUpOrg email={email}/>
      </Modal.Body>
    </Modal>
  );
};

export default TrialSignUpOrgModal;