import { React } from 'react';
import { Modal } from "react-bootstrap"
import { FirebaseAuthOrg } from '../../FirebaseAuth';

const OrgSigninModal = ({ show, onHide, email }) => {

  return (
    <Modal show={show} onHide={onHide} lassName="no-border">
      <Modal.Header closeButton className='gr-bg text-light'>
        <button className="btn-close invisible" disabled={true}></button>
        <Modal.Title>Organization Sign in</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FirebaseAuthOrg />
      </Modal.Body>
    </Modal>
  );
};

export default OrgSigninModal;